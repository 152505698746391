import { DiscordIcon, TwitterIcon } from "@fscrypto/ui";
import { LinkedinIcon } from "lucide-react";
import { Footer as RebrandFooter } from "@fscrypto/flip-kit/footer";

const navigation = {
  social: [
    {
      name: "Twitter",
      href: "https://twitter.com/flipsidecrypto",
      icon: <TwitterIcon />,
    },
    {
      name: "Discord",
      href: "https://discord.gg/ZmU3jQuu6W",
      icon: <DiscordIcon />,
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/flipside-crypto",
      icon: <LinkedinIcon className="size-4" />,
    },
  ],
};

export default function Footer() {
  return <RebrandFooter />;
}
