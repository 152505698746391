import { SVGProps, memo } from "react";
const SvgXIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M18.9015 3.5091H21.2483L15.8748 9.54569L22.2 17.7091H17.2608L13.4268 12.7845L9.02297 17.7091H6.67453L12.407 11.2435L6.36 3.5091H11.4273L14.8705 8.06593L18.9015 3.5091ZM18.0764 16.2563H19.4157L11.0859 4.89149H9.66453L18.0764 16.2563Z"
      fill="currentColor"
    />
  </svg>
);
export const XIcon = memo(SvgXIcon);
